import {Box, Button, Container, Stack, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import {Footer} from "./Footer";
import {APIurl} from "../utils/Utils";
import {useSearchParams} from "react-router-dom";
import TTIcon from "../assets/tt-icon.svg"
import IGIcon from "../assets/ig-icon.svg"


export function Qr() {

    const [searchParams, _setSearchParams] = useSearchParams();
    const instagramAppLink = getInstagramAppLink()//"instagram://media?id=3562178741130457354";
    const instagramWebLink = "https://www.instagram.com/p/DFvZrEbh3EK";

    useEffect(() => {
        const id = searchParams.get("id") === null ? "qr" : searchParams.get("id")
        addScan(id).then(() => {})
    }, [searchParams])


    async function addScan(id) {
        try {
            console.log(id)
            await fetch(`${APIurl}/addScan/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
            redirectToInstagram(instagramAppLink, instagramWebLink);
        }
    }

    function redirectToInstagram(appLink, webLink) {
        let opened = false;

        // Creiamo un <a> invisibile per aprire l'app
        const anchor = document.createElement("a");
        anchor.href = appLink;
        anchor.style.display = "none";
        document.body.appendChild(anchor);

        // Tentiamo di aprire l'app
        anchor.click();

        // Se la pagina diventa "nascosta", significa che l'app si è aperta → blocchiamo il redirect web
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "hidden") {
                opened = true;
            }
        }, { once: true });

        // Dopo 500ms, se l'app non si è aperta, vai alla versione web
        setTimeout(() => {
            if (!opened) window.location.href = webLink;
        }, 500);
    }

    function getInstagramAppLink() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Controlla se è un dispositivo iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "instagram://media?id=3562178741130457354";
        }

        // Controlla se è un dispositivo Android
        else if (/android/i.test(userAgent)) {
            return "intent://www.instagram.com/p/DFvZrEbh3EK/#Intent;package=com.instagram.android;scheme=https;end";
        }

        return null; // Fallback se il dispositivo non è riconosciuto
    }

    return (
        <Box sx={{height:"100vh"}}>
            <Container sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", textAlign:"center", py:2}}>
                <Box />
                <Box sx={{width:{xs:"100%", md:"70%", lg:"50%"}}}>
                    <Stack spacing={4}>
                        <Typography level="h1" textTransform="uppercase" fontWeight="bold" sx={{wordBreak: "break-all"}}
                                    lineHeight="2rem"
                        >
                            Saranno<br/>Pure<br/>Cazzi<br/>Miei
                        </Typography>

                        <Stack spacing={2}>
                            <a href="https://www.instagram.com/sarannopurecazzimiei" target="_blank" rel="noreferrer">
                                <Button sx={{textTransform: "uppercase", borderRadius:"10px"}} size="lg" className="buttonIg"
                                    startDecorator={<img src={IGIcon} height={16} width={16} style={{filter:"invert(1)"}} alt="IGLogo" />}
                                >
                                    Instagram
                                </Button>
                            </a>
                            <a href="https://www.tiktok.com/@sarannopurecazzimiei" target="_blank" rel="noreferrer">
                                <Button sx={{textTransform: "uppercase", borderRadius:"10px", border:"solid 1px #fff"}} size="lg" className="buttonTT"
                                        startDecorator={<img src={TTIcon} height={16} width={16} style={{filter:"invert(1)"}} alt="TTLogo" />}
                                >
                                    Tik Tok
                                </Button>
                            </a>
                        </Stack>
                    </Stack>
                </Box>
                <Footer/>
            </Container>
        </Box>
    )
}
