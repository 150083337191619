import {Link} from "react-router-dom";
import {Stack, Typography} from "@mui/joy";


export function Footer() {
    return (
        <Stack direction="row" spacing={{xs:1, sm:2, md:3}} flexWrap="wrap" useFlexGap justifyContent="center">
            <Link to="/">
                <Typography level="body-xs" textTransform="uppercase">Home</Typography>
            </Link>
            {
                /*
            <Link to="/shop">
                <Typography level="body-xs" textTransform="uppercase">Shop</Typography>
            </Link>
                 */
            }
            <Link to="/ask">
                <Typography level="body-xs" textTransform="uppercase">Fai una domanda</Typography>
            </Link>
            <a href="https://www.instagram.com/sarannopurecazzimiei" target="_blank" rel="noreferrer">
                <Typography level="body-xs" textTransform="uppercase">Instagram</Typography>
            </a>
            <a href="https://www.tiktok.com/@sarannopurecazzimiei" target="_blank" rel="noreferrer">
                <Typography level="body-xs" textTransform="uppercase">Tik Tok</Typography>
            </a>
            <a href="mailto:info@sarannopurecazzimiei.it" target="_blank" rel="noreferrer">
                <Typography level="body-xs" textTransform="uppercase">Contatti</Typography>
            </a>
        </Stack>
    )
}